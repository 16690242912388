@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
  --color-payback: #0046aa;
  --color-primary: #1e40af;
  --color-secondary: #00489c;
  --color-dark-gray: #707070;
  --top-image-gray: #e4e4e4;
  --category-badges-gray: #f5f5f5;
  --tv-live-background: #e6c3bd80;
  --tv-live-color: #707070;
}

*::-webkit-scrollbar {
  position: absolute !important;
  width: 8px;
  z-index: 9999;
  background: #c5c5c5;
}

*::-webkit-scrollbar-thumb {
  background-color: #f6f6f6;
  border-radius: 20px;
  border: 3px solid #f6f6f6;
  z-index: 9999 !important;
}

.bg-top-image {
  background-color: var(--top-image-gray);
}

.icon-size {
  width: 1.25rem;
  height: auto;
}

.scrollbar-width::-webkit-scrollbar {
  display: none;
}

.scrollbar-width {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  scroll-behavior: smooth;
}

.image-center {
  background-position: center;
  background-size: 47rem;
  background-repeat: no-repeat;
}

.image-center-size-25 {
  background-position: center;
  background-size: 200%;
  background-repeat: no-repeat;
}

/** Category Badges */
.category-bg-gray {
  background-color: var(--category-badges-gray);
}

.hover\:category-bg-gray:hover {
  background-color: var(--category-badges-gray);
}

/*
    https://github.com/tailwindlabs/tailwindcss/discussions/2394
    https://github.com/tailwindlabs/tailwindcss/pull/5732
*/
@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.react-pdf__Page__canvas {
  width: 100% !important;
  height: auto !important;
}

/* override tailwind preflight for strapi styles */
div[class*="text-block-"] ul {
  margin: 1.12em 0;
  padding-left: 40px;
  list-style: disc;
}

div[class*="text-block-"] ol {
  margin: 1.12em 0;
  padding-left: 40px;
  list-style: decimal;
}

div[class*="text-block-"] h1 {
  margin: .67em 0;
  @apply uppercase text-3xl;
}

div[class*="text-block-"] h2 {
  margin: .75em 0;
  font-size: 1.65rem;
}

div[class*="text-block-"] h3 {
  margin: .83em 0;
  @apply font-bold text-2xl;
}

div[class*="text-block-"] h4 {
  margin: 1.12em 0;
  @apply font-bold text-xl;
}

div[class*="text-block-"] h5 {
  margin:  1.5em 0;
}

div[class*="text-block-"] h6 {
  margin: 1.67em 0;
}

div[class*="text-block-"] hr {
  background-color: black;
  height: 1px;
  margin-bottom: 2.5rem;
  margin-top: 1.75rem;
  border: none;
}

.text-block-description table {
  max-width: 100% !important;
}